<template>
  <div class="session">
    <v-container>
      <v-row justify="center" class="mt-12">
        <v-img :src="appLogo" max-width="200"></v-img>
      </v-row>
      <v-row justify="center" class="mt-8">
        <h2 class="mb-4 primary--text">{{$t('message.login')}}</h2>
      </v-row>
      <v-row justify="center" class="mt-8">
        <base-error :error="error"></base-error>
      </v-row>
      <v-row justify="center" class="mt-8">
        <v-form lazy-validation @submit="submit" class="session__form mb-5">
          <v-text-field
            label="Correo electrónico"
            class="mt-2"
            v-model="email"
            :error-messages="emailError"
            @keydown="emailError=null; error=null"
            required
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Contraseña"
            v-model="password"
            :error-messages="passwordError"
            @keydown="passwordError=null;error=null"
            type="password"
            required
          ></v-text-field>
          <v-btn
            :loading="isLoading"
            large
            type="submit"
            block
            color="primary"
            class="mt-5"
          >{{$t('message.loginNow')}}</v-btn>
        </v-form>
      </v-row>
      <v-row justify="center" class="mt-4">
        <router-link
          class="secondary--text"
          to="/session/forgot-password"
        >{{$t('message.forgotPassword')}}</router-link>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { SessionsService } from '@/api/api.service'
import AppConfig from '@/constants/app-config'
import { to } from '@/helpers'
import { errorMessage } from '@/mixins'
import { SESSION_LOGIN, SESSION_LOGOUT } from '@/store/actions.type'
export default {
  mixins: [errorMessage],
  data() {
    return {
      isLoading: false,
      error: null,
      email: '',
      password: '',
      appLogo: AppConfig.appLogo,
      emailError: null,
      passwordError: null,
      roleError: null
    }
  },
  methods: {
    async submit(evt) {
      evt.preventDefault()
      const credentials = {
        email: this.email.trim(),
        password: this.password
      }
      this.isLoading = true
      this.error = null

      const [err] = await to(this.$store.dispatch(SESSION_LOGIN, credentials))
      if (err) {
        this.error = err
        this.isLoading = false
        if (err.status === 422) {
          this.emailError = this.errorMessage(err, 'email')
          this.passwordError = this.errorMessage(err, 'password')
        }
      } else {
        const [err, response] = await to(SessionsService.me())
        this.isLoading = false

        if (err) {
          this.error = err
        } else {
          // Only admins can access (for comex version)
          const roles = response.data.roles ? response.data.roles : []
          let isAdmin = false

          for (let i = 0; i < roles.length; i++) {
            if (roles[i].slug === 'admin') {
              this.isLoading = false
              isAdmin = true
              break
            }
          }

          if (isAdmin) {
            this.$router.push('/issues')
          } else {
            await to(this.$store.dispatch(SESSION_LOGOUT))
            this.error = new Error('El rol no es válido, asigne un rol')
            this.roleError = this.errorMessage(this.error, 'role')
          }
        }
      }
    }
  }
}
</script>
